<template>
    <div>
      <app-main>
        <template v-slot:main-content>
          <div>
            <div class="query-header">
                <el-form label-width="100px" :model="form">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="快递单号">
                                <el-input v-model="form.waybill_code" size="small" class="input" placeholder="快递单号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="状态">
                              <el-select class="input" size="small" placeholder="请选择" v-model="form.status" clearable @change="errOrderList">
                                <el-option label="未处理" value="1"></el-option>
                                <el-option label="已处理" value="2"></el-option>
                              </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item>
                                <el-button style="margin-left: -80px;" type="primary" size="small" @click="errOrderList">查询</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!-- 表格 -->
            <div class="table">
              <!-- 内容 -->
              <div class="table-main">
                <el-table :data="moneyObj.data" class="el-table-main" :header-cell-style="{background:'#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500'}">
                  <el-table-column label="异常订单原因" align="center">
                    <template slot-scope="scope"><span style="color: red">{{ scope.row.error_remark }}</span></template>
                  </el-table-column>
                  <el-table-column label="仓库" prop="storage_name" align="center"></el-table-column>
                  <el-table-column label="快递公司" prop="deliver_name" align="center"></el-table-column>
                  <el-table-column label="快递单号" width="220" prop="waybill_code" align="center"></el-table-column>
                  <el-table-column label="包裹号" prop="package_no" align="center"></el-table-column>
                  <el-table-column label="商品名称" prop="goods_name" align="center"></el-table-column>
                  <el-table-column label="创建时间">
                    <template slot-scope="scope">{{timeConversion(scope.row.createtime)}}</template>
                  </el-table-column>
                  <el-table-column label="状态" align="center">
                    <template slot-scope="scope">
                        <el-tag type="danger" v-if="scope.row.status == 1">待处理</el-tag>
                        <el-tag type="success" v-if="scope.row.status == 2">已处理</el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="primary" size="small" v-if="scope.row.status == 1" @click="dealWidth(scope.row.id)">我已处理</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="pagination">
                  <el-pagination
                    background
                    @current-change="currentChange"
                    :page-size="moneyObj.per_page"
                    layout="prev, pager, next"
                    :current-page="moneyObj.current_page"
                    :total="moneyObj.total">
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </template>
      </app-main>
    </div>
  </template>
<script>
import AppMain from 'components/baseStructure/AppMain'
import { errorOrderListApi, dealWithErrorOrder } from 'network/api'
import { timeConversion } from 'utils/utils'
export default {
  components: {
    AppMain
  },
  inject: ['mainReload'],
  data () {
    return {
      rechargeAmount: ['20', '100', '500', '2000'],
      num: undefined,
      moneyObj: {},
      currentPage: 1,
      id: '',
      form: {}
    }
  },
  computed: {
    timeConversion () {
      return timeConversion
    }
  },
  created () {
    this.errOrderList()
    setTimeout(() => {
      this.$notify.closeAll()
    }, 400)
  },
  methods: {
    errOrderList () {
      errorOrderListApi({
        ...this.form,
        page: this.currentPage
      }).then(res => {
        console.log(res, 'resres')
        this.moneyObj = res.data
      })
    },
    dealWidth (id) {
      this.$confirm('该订单是否已经处理?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        dealWithErrorOrder({ id }).then(res => {
          if (res.code === 1) {
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
            this.errOrderList()
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      })
    },
    currentChange (val) {
      this.currentPage = val
      this.errOrderList()
    }
  }
}
</script>
<style scoped src="styles/views/account/balance.css"></style>
<style>
  .query-header{
    background: #fff;
    padding-top: 20px;
  }
  .rechargeDialog .el-dialog__header, .tixianDialog .el-dialog__header {
    border-bottom: 1px solid #e8e8e8;
  }
  .input-num .el-input-number--small {
    width: 180px;
  }
  .input-item.number .el-input-number {
    width: 324px;
  }
</style>
